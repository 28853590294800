import React, { useState } from 'react'
import { StaticImage, getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Product from '../components/molecules/Product'
import ContactSection from '../components/organisms/ContactSection'
import Banner from '../components/organisms/Banner'
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ProductsPage = ({ pageContext, data }) => {
  const { t } = useTranslation()
  const [productClass, setProductClass] = useState('')
  return (
    <Layout>
      <Seo title={t('pageTitle')} />
      <section className='px-4 border-b-12 lg:border-b-36 border-secondary md:border-secondary py-8 md:py-20'>
        <div className='w-full max-w-screen-xl mx-auto'>
          <h2 className='text-secondary text-lg uppercase font-bold has-decorator mb-10'>
            {t('pageTitle')}
          </h2>
          <div className={`flex space-x-2 md:space-x-10 mt-10 xl:mt-16 ${productClass}`}>
            <button className={`button button--wide button--secondary a1n1`} onClick={() => setProductClass('a1n1')}>A1 N1, A3 N1</button>
            <button className={`button button--wide button--secondary b4p1`} onClick={() => setProductClass('b4p1')}>B4 P1</button>
          </div>
          <div className={`mt-10 xl:mt-16 flex flex-wrap -mx-4 xl:-mx-8 ${productClass}`}>
            {data.allStrapiProducts.nodes.map(({ id, short_name, short_description, product_class, locale, slug, mainImage }) => {
              return (<div key={id} className={`px-4 xl:px-8 mb-8 xl:mb-16 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 ${product_class}`}>
                <Product
                  shortName={short_name}
                  shortDescription={short_description}
                  url={`/${locale}/produkt/${slug}`}
                  image={getImage(mainImage?.localFile)}
                />
              </div>)
            })}
          </div>
        </div>
      </section>

      <Banner
        image={
          <StaticImage
            src='../images/banner1.jpg'
            layout='fullWidth'
            transformOptions='fit'
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt={t('bannerSection.header')}
            style={{
              height: '100%',
            }}
          />
        }
        text={t('bannerSection.header')}
      />
      <ContactSection />
    </Layout>
  )
}

export default ProductsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "productsPage"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStrapiProducts(
      sort: {fields: strapiId, order: DESC}
      filter: {locale: {eq: $language}}
    ) {
      nodes {
        id
        name
        short_name
        description
        short_description
        slug
        locale
        product_class
        mainImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        localizations {
          locale
        }
      }
    }
  }
`;
